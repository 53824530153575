<template>
    <div class="dialogContentComPonent" id="messageContent" ref="msgContainer">
        <div class="message" v-for="(msg, index) in actions" :key="index">
            <!-- 机器人消息 -->
            <template>
                <div class="user-detail" v-if="msg.content != null && JSON.stringify(msg.content)!='{}'">
                        <div
                            class="media-body"
                            v-if="
                                (msg.type == 'answer_text' ||
                                    msg.type == 'answer_welcometext' ||
                                    msg.type == 'answer_correct') &&
                                msg.content.list != null &&
                                msg.content.list[0] != '__welcome__'
                            "
                        >
                            <p
                                v-html="
                                    msg.content.list != null
                                        ? msg.content.list[0]
                                        : ''
                                "
                            ></p>
                        </div>
                        <div
                            class="media-body"
                            style="max-width: 325px"
                            v-else-if="msg.type == 'answer_rich_text'"
                        >
                            <p v-html="msg.content.html"></p>
                        </div>
                        <!-- <div
                            class="media-body"
                            style="max-width: 350px"
                            v-else-if="msg.type == 'action_confirm_value'"
                        >
                            <TextMessage :disable="true" :text="msg.content">
                            </TextMessage>
                        </div> -->
                        <!-- <div
                            class="media-body"
                            style="max-width: 350px"
                            v-else-if="msg.type == 'action_alert_iframe'"
                        >
                            <ActionAlert
                                :actionAlertIframe="msg.content"
                            ></ActionAlert>
                            <div
                                @click="gotoAlert(msg.content.template)"
                                class="alertUrl"
                            >
                                {{ msg.content.template }}
                            </div>
                        </div> -->
                        <!-- <div
                            class="media-body"
                            style="max-width: 350px"
                            v-else-if="msg.type == 'action_form'"
                        >
                            <FormTemplate
                                :disable="true"
                                :formList="msg.content"
                            >
                            </FormTemplate>
                        </div> -->
                        <!-- <div
                            class="media-body"
                            style="max-width: 350px"
                            v-else-if="msg.type == 'action_feedback'"
                        >
                            <FeedBack
                                :disable="true"
                                :feedBack="msg.content"
                            ></FeedBack>
                        </div> -->

                        <!-- <div
                            class="media-body"
                            style="max-width: 350px"
                            v-else-if="msg.type == 'answer_card_template'"
                        >
                            <TicketMessage
                                :disable="true"
                                :content="msg.content"
                            >
                            </TicketMessage>
                        </div> -->

                        <!-- <div
                            class="media-body"
                            v-else-if="
                                msg.type == 'action_question' &&
                                msg.content != null &&
                                msg.content.questions != null &&
                                msg.content.questions.length > 0
                            "
                        >
                            <p v-html="msg.content.questions[0]"></p>
                        </div> -->
                        <div
                            class="media-body"
                            v-else-if="msg.type == 'answer_rich_text_pro'"
                        >
                            <div v-html="msg.content.html"></div>
                        </div>
                        <div
                            class="media-body"
                            v-else-if="msg.type == 'answer_image'"
                        >
                            <p>
                                <el-image
                                    style="width: 35%"
                                    :src="msg.content.url"
                                ></el-image>
                            </p>
                        </div>
                        <!-- <div
                            v-else-if="msg.type == 'answer_radio'"
                            class="media-body-ps"
                        >
                            <p class="other">{{ msg.content.description }}</p>
                            <p
                                class="ps"
                                v-for="(option, index) in msg.content.options"
                                :key="index"
                            >
                                {{ option.name }}
                            </p>
                        </div> -->
                        <!-- <div
                            v-else-if="msg.type == 'action_satisfaction'"
                            class="media-body-ps"
                        >
                            <p class="other">
                                {{ msg.content.satisfactionDes }}
                            </p>
                            <p
                                class="ps"
                                v-for="(option, index) in ['满意', '不满意']"
                                :key="index"
                            >
                                {{ option }}
                            </p>
                        </div> -->

                        <div
                            v-else-if="msg.type == 'answer_video'"
                            class="media-body"
                        >
                            <p>
                                <video-player
                                    v-if="msg.type === 'answer_video'"
                                    class="video-player vjs-custom-skin"
                                    :options="videoOptions(msg.content)"
                                    style="width: 350px"
                                />
                            </p>
                        </div>
                        <div v-else-if="msg.type == 'action_transfer'">
                            <!--                                        机器人回复为转人工组件内容 -->
                        </div>
                        <div v-else-if="msg.type == 'action_transfer_2_ewechat'">
                            <!--                                        机器人回复为转企业微信客服组件内容 -->
                        </div>
                        <!-- <div
                            v-else-if="
                                msg.type === 'action_send_message_to_bot'
                            "
                        >
                            <span>{{ msg.content.description }}</span>
                            <span
                                v-for="(cell, index) in msg.content.options"
                                :key="'_' + index"
                                class="link-cell cursor"
                                >{{ cell.name }}</span
                            >
                        </div> -->
                        <div v-else>
                            <p>{{ msg }}</p>
                        </div>
                    </div>
            </template>
        </div>
    </div>
</template>

<script>
import "video.js/dist/video-js.css";
// import { videoPlayer } from "vue-video-player";
import Emotion from "../../../components/Emotion/index";
import FileType from "askbot-dragon/src/components/FileType";
import TicketMessage from "askbot-dragon/src/components/message/TicketMessage";
import TextMessage from "askbot-dragon/src/components/message/TextMessage";
import FormTemplate from "askbot-dragon/src/components/formTemplate";
import FeedBack from "askbot-dragon/src/components/feedBack";
import ActionAlert from "askbot-dragon/src/components/ActionAlertIframe";

export default {
    props: ["actions"],
    components: {
        TicketMessage,
        TextMessage,
        FormTemplate,
        FeedBack,
        ActionAlert,
        Emotion,
        FileType,
    },
    data() {
        return {};
    },
    watch: {},
    mounted() {},
    methods: {
        gotoAlert(url) {
            window.open(url);
        },
        previewImage(_this) {
            this.showPreview = true;
            this.previewImageUrl = _this.src;
        },
        videoOptions(videoContent) {
            return {
                muted: true,
                language: "en",
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                sources: [
                    {
                        type: "video/mp4",
                        src: videoContent.url,
                    },
                ],
                width: "350",
                height: "160",
            };
        },
    },
};
</script>

<style lang="less">
.dialogContentComPonent {
    width: calc(100% - 16px);
    background-color: rgb(245, 245, 245);
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    .message-notice {
        display: inline-block;
        text-align: center;
        margin-bottom: 20px;
        width: 100%;
        background-color: transparent !important;
    }
    .notice-content {
        display: inline-block;
        padding: 5px 10px;
        border-radius: 5px;
        background-color: #e8eaec;
        color: #666666;
        font-size: 12px;
    }
    .notice-content-day {
        display: inline-block;
        padding: 5px 10px;
        .line-left {
            display: inline-block;
            width: 100px;
            height: 1px;
            margin-bottom: 5px;
            margin-right: 5px;
            //background:#dcdcdc;
            background: -webkit-linear-gradient(left, #fff -4%, #666666 50%);
        }
        .line-text {
            color: #666666;
            font-size: 12px;
        }
        .line-right {
            display: inline-block;
            width: 100px;
            height: 1px;
            margin-bottom: 5px;
            margin-left: 5px;
            //background:#dcdcdc;
            background: -webkit-linear-gradient(left, #666666 50%, #fff 100%);
        }
    }
    //messages
    .message {
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-start;
        .user-detail {
            display: flex;
            -ms-flex-align: flex-start;
            align-items: flex-start;
            .media-body {
                //max-width: calc(100vw - 800px);
                background-color: white;
                // border: 1px solid #e4e3e3;
                word-break: break-word;
                flex: 1;
                min-height: 25px;
                line-height: 25px;
                border-radius: 15px;
                border-top-left-radius: 0px;
                padding: 10px 10px 0 15px;
                text-align: left;

                h2 {
                    font-size: 18px;
                }
                p {
                    margin-bottom: 5px;
                    font-size: 14px;
                    word-wrap: break-word;
                    word-break: break-all;
                    overflow: hidden;
                    display: flex;
                    flex-wrap: wrap;
                }
                .content {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                }
                .voicemessage {
                    width: 200px;
                    height: 35px;
                    line-height: 35px;
                    background-color: #e8eaec;
                    border-radius: 10px;
                    .icon-vioce {
                        font-size: 24px;
                        float: left;
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                    .time {
                        font-size: 14px;
                        margin-left: 20px;
                    }
                }
                span {
                    margin-bottom: 5px;
                    font-size: 12px;
                    color: #a3a4a5;
                    float: left;
                }
                .botname {
                    margin-left: 10px;
                    float: right;
                }
                .mb-0 {
                    margin-bottom: 0 !important;
                    text-align: left;
                    line-height: 1.3;
                    margin-top: 0;
                    font-size: 12px;
                }
            }
            .user-image {
                width: 35px;
                height: 35px;
                margin-left: 10px;
                margin-right: 10px;
                img {
                    width: 35px;
                    height: 35px;
                }
                span {
                    color: #666666;
                    font-size: 12px;
                }
            }
            .media-body-ps {
                //max-width: calc(100vw - 800px);
                background-color: #f8f8f8;
                border: 1px solid #e4e3e3;
                word-break: break-word;
                flex: 1;
                min-height: 25px;
                line-height: 25px;
                border-radius: 25px;
                border-top-left-radius: 0px;
                text-align: left;
                h2 {
                    font-size: 18px;
                }
                .other {
                    margin-bottom: 5px;
                    font-size: 14px;
                    word-wrap: break-word;
                    word-break: break-all;
                    overflow: hidden;
                    padding: 10px 10px 0 15px;
                }
                .ps {
                    padding: 5px 10px 5px 15px;
                    height: 25px;
                    line-height: 25px;
                    border-top: 1px solid #e4e3e3;
                    background-color: #ffffff;
                    color: #366AFF;
                    cursor: pointer;
                }
                .end {
                    border-bottom-left-radius: 25px !important;
                    border-bottom-right-radius: 25px !important;
                }
                .voicemessage {
                    width: 200px;
                    height: 35px;
                    line-height: 35px;
                    background-color: #e8eaec;
                    border-radius: 10px;
                    .icon-vioce {
                        font-size: 24px;
                        float: left;
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                    .time {
                        font-size: 14px;
                        margin-left: 20px;
                    }
                }
                span {
                    margin-bottom: 5px;
                    font-size: 12px;
                    color: #a3a4a5;
                    float: left;
                }
                .botname {
                    margin-left: 10px;
                    float: right;
                }
                .mb-0 {
                    margin-bottom: 0 !important;
                    text-align: left;
                    line-height: 1.3;
                    margin-top: 0;
                    font-size: 12px;
                }
            }
        }
        .video-player,
        .video-js .vjs-icon-placeholder {
            width: 300px;
            height: 172px;
            display: block;
        }
        .el-image {
            float: right;
            width: 300px !important;
            height: auto !important;
        }
    }
    .message-kf {
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-end;
        .user-detail {
            display: flex;
            -ms-flex-align: flex-start;
            align-items: flex-start;
            .media-body {
                //max-width: calc(100vw - 800px);
                border: 1px solid #c1e5ff !important;
                background-color: #c1e5ff !important;
                word-break: break-word;
                flex: 1;
                min-height: 25px;
                line-height: 25px;
                border-radius: 15px;
                border-top-right-radius: 0px;
                padding: 10px 10px 0 10px;
                text-align: left;
                h2 {
                    font-size: 18px;
                }
                p {
                    margin-bottom: 5px;
                    font-size: 14px;
                    word-wrap: break-word;
                    word-break: break-all;
                    overflow: hidden;
                }
                .voicemessage {
                    width: 200px;
                    height: 35px;
                    line-height: 35px;
                    background-color: #e8eaec;
                    border-radius: 10px;
                    .icon-vioce {
                        font-size: 24px;
                        float: left;
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                    .time {
                        font-size: 14px;
                        margin-left: 20px;
                    }
                }
                span {
                    margin-bottom: 5px;
                    font-size: 12px;
                    color: #a3a4a5;
                    float: right;
                }
                .botname {
                    margin-left: 10px;
                    float: right;
                }
                .mb-0 {
                    margin-bottom: 0 !important;
                    text-align: left;
                    line-height: 1.3;
                    margin-top: 0;
                    font-size: 12px;
                }
            }
        }
        .video-player,
        .video-js .vjs-icon-placeholder {
            width: 300px;
            height: 172px;
            display: block;
        }
        .el-image {
            float: right;
            width: 300px !important;
            height: auto !important;
        }
    }
}
</style>