import { render, staticRenderFns } from "./FeedbackDetail.vue?vue&type=template&id=38c0e8b8"
import script from "./FeedbackDetail.vue?vue&type=script&lang=js"
export * from "./FeedbackDetail.vue?vue&type=script&lang=js"
import style0 from "./FeedbackDetail.vue?vue&type=style&index=0&id=38c0e8b8&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports