var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"msgContainer",staticClass:"dialogContentComPonent",attrs:{"id":"messageContent"}},_vm._l((_vm.actions),function(msg,index){return _c('div',{key:index,staticClass:"message"},[[(msg.content != null && JSON.stringify(msg.content)!='{}')?_c('div',{staticClass:"user-detail"},[(
                            (msg.type == 'answer_text' ||
                                msg.type == 'answer_welcometext' ||
                                msg.type == 'answer_correct') &&
                            msg.content.list != null &&
                            msg.content.list[0] != '__welcome__'
                        )?_c('div',{staticClass:"media-body"},[_c('p',{domProps:{"innerHTML":_vm._s(
                                msg.content.list != null
                                    ? msg.content.list[0]
                                    : ''
                            )}})]):(msg.type == 'answer_rich_text')?_c('div',{staticClass:"media-body",staticStyle:{"max-width":"325px"}},[_c('p',{domProps:{"innerHTML":_vm._s(msg.content.html)}})]):(msg.type == 'answer_rich_text_pro')?_c('div',{staticClass:"media-body"},[_c('div',{domProps:{"innerHTML":_vm._s(msg.content.html)}})]):(msg.type == 'answer_image')?_c('div',{staticClass:"media-body"},[_c('p',[_c('el-image',{staticStyle:{"width":"35%"},attrs:{"src":msg.content.url}})],1)]):(msg.type == 'answer_video')?_c('div',{staticClass:"media-body"},[_c('p',[(msg.type === 'answer_video')?_c('video-player',{staticClass:"video-player vjs-custom-skin",staticStyle:{"width":"350px"},attrs:{"options":_vm.videoOptions(msg.content)}}):_vm._e()],1)]):(msg.type == 'action_transfer')?_c('div'):(msg.type == 'action_transfer_2_ewechat')?_c('div'):_c('div',[_c('p',[_vm._v(_vm._s(msg))])])]):_vm._e()]],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }