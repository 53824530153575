var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"msgContainer",class:['dialogContentComPonentD',_vm.userMessages.length == 0 ? 'empty_dialogContentComPonentD' : ''],attrs:{"id":"messageContent"}},[(_vm.userMessages.length == 0)?_c('div',{staticClass:"empty-tips-d"},[_c('img',{attrs:{"src":require("../../../assets/images/news-history.png"),"alt":""}}),_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('trainList.detailList.leftList'))+"“ "),_c('i',{staticClass:"iconfont guoran-tongyichicun-dianjichakantuli"}),_vm._v(" "+_vm._s(_vm.$t('trainList.detailList.viewMessageRecords'))+"”")]),_c('p',[_vm._v(_vm._s(_vm.$t('trainList.detailList.button')))])])]):_vm._e(),_vm._l((_vm.userMessages),function(item,index){return _c('div',{key:index},[(
                item.source == 'WEB_SOCKET' ||
                item.source == 'PWX_SOCKET' ||
                item.source == 'EKB_MALL' ||
                item.source == 'DING_SOCKET' || 
                item.source == 'WE_CHART_KF_CLIENT' ||
                item.source == 'DINGTALK_GROUP' ||
                item.source == 'DINGTALK_BOT' || 
                item.source == 'APPLET' || 
                item.source == 'FEISHU_SOCKET' || 
                item.source == 'FEISHU_CHAT' ||
                item.source == 'YUNZHIJIA_SOCKET' ||
                item.source == 'ZHIYUAN_SOCKET'
            )?_c('div',{staticClass:"message message-kf"},[_c('div',{staticClass:"user-detail"},[_c('div',{staticClass:"media-body",attrs:{"id":'chat' + item.id}},[(
                            item.type == 'user_action_to_satisfaction' ||
                            item.type == 'user_action_to_unsatisfactory'
                        )?_c('p',[_vm._v(" "+_vm._s(JSON.parse(item.content).input)+" ")]):(item.type == 'FORM_SUBMIT')?_c('div',[_c('FormTemplate',{attrs:{"submit":true,"disable":true,"formList":JSON.parse(item.content)}})],1):(item.type === 'FEEDBACK')?_c('p',[_vm._v(" "+_vm._s(JSON.parse(item.content).feedback.text)+" ")]):(item.type == 'VALUE_CONFIRM')?_c('div',[_c('TextMessage',{attrs:{"submit":true,"disable":true,"text":JSON.parse(item.content)}})],1):(item.type == 'TEMPLATE_CARD_BUTTON')?_c('p',[_vm._v(" "+_vm._s(JSON.parse(item.content).name)+" ")]):(item.type == 'VIDEO')?_c('div',[_c('video-player',{staticClass:"video-player vjs-custom-skin",staticStyle:{"width":"350px"},attrs:{"options":_vm.videoOptions(JSON.parse(item.content))}})],1):(item.type == 'IMAGE')?_c('div',[_c('el-image',{staticStyle:{"width":"35%"},attrs:{"src":JSON.parse(item.content).urls[0]}})],1):(item.type == 'GOTO')?_c('div',[_vm._v(" ["+_vm._s(_vm.$t('trainList.detailList.interactiveCard'))+"] ")]):(item.type == 'FAST_SELECT')?_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(JSON.parse(item.content).text)}})]):(item.type == 'INTELLIGENT')?_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(JSON.parse(item.content).name)}})]):(item.type == 'RADIO_V2')?_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(JSON.parse(item.content).optionName)}})]):_c('p',{domProps:{"innerHTML":_vm._s(
                            item.type == 'RADIO'
                                ? item.content.split('_')[3]
                                : item.content
                        )}}),_c('span',{staticClass:"time-span"},[_vm._v(_vm._s(new Date(item.time).Format("yyyy-MM-dd hh:mm:s")))])]),_c('div',{staticClass:"user-image"},[_c('img',{attrs:{"src":require("./../../../assets/images/Female_user_help.png")}}),_c('span',[_vm._v(_vm._s(_vm.$t('trainList.detailList.user')))])])])]):_vm._e(),(item.source == 'WEBSITE_SOCKET')?_c('div',{staticClass:"message message-kf"},[_c('div',{staticClass:"user-detail"},[_c('div',{staticClass:"media-body"},[(
                            item.type == 'user_action_to_satisfaction' ||
                            item.type == 'user_action_to_unsatisfactory'
                        )?_c('p',[_vm._v(" "+_vm._s(JSON.parse(item.content).input)+" ")]):(item.type == 'FORM_SUBMIT')?_c('div',[_c('FormTemplate',{attrs:{"submit":true,"disable":true,"formList":JSON.parse(item.content)}})],1):(item.type === 'FEEDBACK')?_c('p',[_vm._v(" "+_vm._s(JSON.parse(item.content).feedback.text)+" ")]):(item.type == 'VALUE_CONFIRM')?_c('div',[_c('TextMessage',{attrs:{"submit":true,"disable":true,"text":JSON.parse(item.content)}})],1):(item.type == 'TEMPLATE_CARD_BUTTON')?_c('p',[_vm._v(" "+_vm._s(JSON.parse(item.content).name)+" ")]):(
                            item.type === 'office_file' ||
                            item.type === 'other_file'
                        )?_c('div',{staticClass:"pre-message"},[_c('file-type',{attrs:{"urls":JSON.parse(item.content).urls}})],1):(item.type == 'VIDEO')?_c('div',[_c('video-player',{staticClass:"video-player vjs-custom-skin",staticStyle:{"width":"350px"},attrs:{"options":_vm.videoOptions(
                                    JSON.parse(item.content).urls[0]
                                )}})],1):(item.type == 'IMAGE')?_c('div',[_c('el-image',{staticStyle:{"width":"35%"},attrs:{"src":JSON.parse(item.content).urls[0]}})],1):(item.type == 'INTELLIGENT')?_c('div',[_vm._v(" "+_vm._s(JSON.parse(item.content).name)+" ")]):(item.type == 'FAST_SELECT')?_c('div',[_vm._v(" "+_vm._s(JSON.parse(item.content).text)+" ")]):_c('p',{domProps:{"innerHTML":_vm._s(
                            item.type == 'RADIO'
                                ? item.content.split('_')[3]
                                : item.content.replace(
                                      /\#[\u4E00-\u9FA5]{1,3}\;/gi,
                                      _vm.emotion
                                  )
                        )}}),_c('span',{staticClass:"time-span"},[_vm._v(_vm._s(new Date(item.time).Format("yyyy-MM-dd hh:mm:s")))])]),_c('div',{staticClass:"user-image"},[_c('img',{attrs:{"src":require("./../../../assets/images/Female_user_help.png")}}),_c('span',[_vm._v(_vm._s(_vm.$t('trainList.detailList.user')))])])])]):_vm._e(),(item.source == 'MINI_PROGRAM_WEB_SOCKET')?_c('div',{staticClass:"message message-kf"},[_c('div',{staticClass:"user-detail"},[_c('div',{staticClass:"media-body"},[(
                            item.type == 'user_action_to_satisfaction' ||
                            item.type == 'user_action_to_unsatisfactory'
                        )?_c('p',[_vm._v(" "+_vm._s(JSON.parse(item.content).input)+" ")]):(item.type == 'TEMPLATE_CARD_BUTTON')?_c('p',[_vm._v(" "+_vm._s(JSON.parse(item.content).name)+" ")]):_c('p',{domProps:{"innerHTML":_vm._s(
                            item.type == 'RADIO'
                                ? item.content.split('_')[3]
                                : item.content
                        )}}),_c('span',{staticClass:"time-span"},[_vm._v(_vm._s(new Date(item.time).Format("yyyy-MM-dd hh:mm:s")))])]),_c('div',{staticClass:"user-image"},[_c('img',{attrs:{"src":require("./../../../assets/images/Female_user_help.png")}}),_c('span',[_vm._v(_vm._s(_vm.$t('trainList.detailList.user')))])])])]):_vm._e(),(item.type == 'user_action_to_satisfaction')?_c('div',{staticClass:"message"},[[(JSON.parse(item.content) != null)?_c('div',{staticClass:"user-detail"},[_vm._m(0,true),(
                            JSON.parse(item.content).reply_options !=
                                null &&
                            JSON.parse(item.content).reply_options.length >
                                0
                        )?_c('div',{staticClass:"media-body-ps"},[_c('p',{staticClass:"other",domProps:{"innerHTML":_vm._s(JSON.parse(item.content).reply_text)}}),_vm._l((JSON.parse(
                                item.content
                            ).reply_options),function(option,index){return (
                                JSON.parse(item.content).reply_options !=
                                    null &&
                                JSON.parse(item.content).reply_options
                                    .length > 0
                            )?_c('p',{key:index,staticClass:"ps"},[_vm._v(" "+_vm._s(option.name)+" ")]):_vm._e()}),_c('span',{staticClass:"time-span",staticStyle:{"padding":"0 15px"}},[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source == 'GUORAN_BOT')?_c('span',{staticClass:"botname"}):(
                                item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                            )?_c('span',{staticClass:"botname",staticStyle:{"margin-right":"15px"}},[_vm._v(_vm._s(item.sourceId))]):_vm._e()],2):_c('div',{staticClass:"media-body"},[_c('p',{domProps:{"innerHTML":_vm._s(JSON.parse(item.content).reply_text)}}),_c('span',{staticClass:"time-span"},[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source == 'GUORAN_BOT')?_c('span',{staticClass:"botname"}):(
                                item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                            )?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceId))]):_vm._e()])]):_vm._e()]],2):_vm._e(),(item.type == 'user_action_to_unsatisfactory')?_c('div',{staticClass:"message"},[[(JSON.parse(item.content) != null)?_c('div',{staticClass:"user-detail"},[_vm._m(1,true),_c('div',{staticClass:"media-body"},[(
                                JSON.parse(item.content).reply_text != null
                            )?_c('p',{staticClass:"other"},[_vm._v(" "+_vm._s(JSON.parse(item.content).reply_text)+" ")]):_vm._e(),_vm._l((JSON.parse(
                                item.content
                            ).options),function(option,index){return (
                                JSON.parse(item.content).reply_options !=
                                    null &&
                                JSON.parse(item.content).reply_options
                                    .length > 0
                            )?_c('p',{key:index,staticClass:"ps"},[_vm._v(" "+_vm._s(option.name)+" ")]):_vm._e()}),_c('span',{staticClass:"time-span"},[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))])],2)]):_vm._e()]],2):_vm._e(),(item.source === 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER')?[(item.type == 'TEXT')?_c('div',{staticClass:"message"},[_c('div',{staticClass:"user-detail"},[_c('div',{staticClass:"user-image"},[_c('img',{attrs:{"alt":"人工客服","src":require("./../../../assets/images/customer_service.png")}}),_c('span',[_vm._v(_vm._s(_vm.$t('trainList.detailList.customerService')))])]),_c('div',{staticClass:"media-body"},[_c('p',{domProps:{"innerHTML":_vm._s(
                                item.content.replace(
                                    /\#[\u4E00-\u9FA5]{1,3}\;/gi,
                                    _vm.emotion
                                )
                            )}}),_c('span',{staticClass:"time-span"},[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER')?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceName))]):_vm._e()])])]):_vm._e(),(item.type === 'image')?_c('div',{staticClass:"message"},[_c('div',{staticClass:"user-detail"},[_c('div',{staticClass:"user-image"},[_c('img',{attrs:{"alt":"人工客服","src":require("./../../../assets/images/customer_service.png")}}),_c('span',[_vm._v(_vm._s(_vm.$t('trainList.detailList.customerService')))])]),_c('div',{staticClass:"media-body"},[_c('p',[_c('el-image',{staticStyle:{"width":"35%"},attrs:{"src":item.content}})],1),_c('span',{staticClass:"time-span"},[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER')?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceName))]):_vm._e()])])]):_vm._e(),(item.type === 'video')?_c('div',{staticClass:"message"},[_c('div',{staticClass:"user-detail"},[_c('div',{staticClass:"user-image"},[_c('img',{attrs:{"alt":"人工客服","src":require("./../../../assets/images/customer_service.png")}}),_c('span',[_vm._v(_vm._s(_vm.$t('trainList.detailList.customerService')))])]),_c('div',{staticClass:"media-body"},[_c('p',[_c('video-player',{staticClass:"video-player vjs-custom-skin",staticStyle:{"width":"350px"},attrs:{"options":_vm.videoOptions({ url: item.content })}})],1),_c('span',{staticClass:"time-span"},[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER')?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceName))]):_vm._e()])])]):_vm._e(),(
                    item.type === 'office_file' ||
                    item.type === 'other_file'
                )?_c('div',{staticClass:"message"},[_c('div',{staticClass:"user-detail"},[_c('div',{staticClass:"user-image"},[_c('img',{attrs:{"alt":"人工客服","src":require("./../../../assets/images/customer_service.png")}}),_c('span',[_vm._v(_vm._s(_vm.$t('trainList.detailList.customerService')))])]),_c('div',{staticClass:"media-body"},[_c('p',[_c('file-type',{attrs:{"urls":JSON.parse(item.content).urls}})],1),_c('span',{staticClass:"time-span"},[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source === 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER')?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceName))]):_vm._e()])])]):_vm._e()]:_vm._e(),(
                item.source == 'GUORAN_BOT' &&
                item.type != 'user_action_to_satisfaction' &&
                item.type != 'user_action_to_unsatisfactory'
            )?_vm._l((JSON.parse(item.content)),function(msg,index){return _c('div',{key:index,staticClass:"message"},[(msg.content != null && JSON.stringify(msg.content)!='{}' && (msg.content.isGlobal == null || msg.content.isGlobal == false))?_c('div',{staticClass:"user-detail"},[_vm._m(2,true),(
                            (msg.type == 'answer_text' ||
                                msg.type == 'answer_welcometext' ||
                                msg.type == 'answer_correct') &&
                            msg.content.list != null &&
                            msg.content.list[0] != '__welcome__'
                        )?_c('div',{staticClass:"media-body"},[_c('p',{domProps:{"innerHTML":_vm._s(
                                msg.content.list != null
                                    ? msg.content.list[0]
                                    : ''
                            )}}),_c('Recocell',{attrs:{"item":msg.content}}),_c('span',{staticClass:"time-span"},[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source == 'GUORAN_BOT')?_c('span',{staticClass:"botname"}):(
                                item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                            )?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceId))]):_vm._e()],1):(msg.type == 'answer_rich_text')?_c('div',{staticClass:"media-body",staticStyle:{"max-width":"325px"}},[_c('p',{class:[msg.content.version == 1?'new-version-rich':''],domProps:{"innerHTML":_vm._s(msg.content.html)}}),_c('Recocell',{attrs:{"item":msg.content}}),_c('span',{staticClass:"time-span"},[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source == 'GUORAN_BOT')?_c('span',{staticClass:"botname"}):(
                                item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                            )?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceId))]):_vm._e()],1):(msg.type == 'action_confirm_value')?_c('div',{staticClass:"media-body",staticStyle:{"max-width":"350px"}},[_c('TextMessage',{attrs:{"disable":true,"text":msg.content}}),_c('span',{staticClass:"time-span"},[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source == 'GUORAN_BOT')?_c('span',{staticClass:"botname"}):(
                                item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                            )?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceId))]):_vm._e()],1):(msg.type == 'action_alert_iframe')?_c('div',{staticClass:"media-body",staticStyle:{"max-width":"350px"}},[_c('ActionAlert',{attrs:{"actionAlertIframe":msg.content}}),_c('div',{staticClass:"alertUrl",on:{"click":function($event){return _vm.gotoAlert(msg.content.template)}}},[_vm._v(" "+_vm._s(msg.content.template)+" ")]),_c('span',{staticClass:"time-span"},[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source == 'GUORAN_BOT')?_c('span',{staticClass:"botname"}):(
                                item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                            )?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceId))]):_vm._e()],1):(msg.type == 'action_form')?_c('div',{staticClass:"media-body",staticStyle:{"max-width":"350px"}},[_c('FormTemplate',{attrs:{"disable":true,"formList":msg.content}}),_c('span',{staticClass:"time-span"},[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source == 'GUORAN_BOT')?_c('span',{staticClass:"botname"}):(
                                item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                            )?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceId))]):_vm._e()],1):(msg.type == 'action_feedback')?_c('div',{staticClass:"media-body",staticStyle:{"max-width":"350px"},attrs:{"id":'chat' + msg.content.feedbackUid}},[_c('FeedBack',{attrs:{"disable":true,"feedBack":msg.content}}),_c('span',{staticClass:"time-span"},[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source == 'GUORAN_BOT')?_c('span',{staticClass:"botname"}):(
                                item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                            )?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceId))]):_vm._e()],1):(msg.type == 'answer_intellect_guide')?_c('div',{staticClass:"media-body",staticStyle:{"max-width":"350px"},attrs:{"id":'chat' + msg.content + index}},[_c('AiGuide',{attrs:{"aiGuide":msg}}),_c('span',[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source == 'GUORAN_BOT')?_c('span',{staticClass:"botname"}):(
                                item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                            )?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceId))]):_vm._e()],1):(msg.type == 'answer_card_template')?_c('div',{staticClass:"media-body",staticStyle:{"max-width":"350px"}},[_c('TicketMessage',{attrs:{"disable":true,"content":msg.content}}),_c('span',{staticClass:"time-span"},[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source == 'GUORAN_BOT')?_c('span',{staticClass:"botname"}):(
                                item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                            )?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceId))]):_vm._e()],1):(
                            msg.type == 'action_question' &&
                            msg.content != null &&
                            msg.content.questions != null &&
                            msg.content.questions.length > 0
                        )?_c('div',{staticClass:"media-body"},[_c('p',{domProps:{"innerHTML":_vm._s(msg.content.questions[0])}}),_c('span',{staticClass:"time-span"},[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source == 'GUORAN_BOT')?_c('span',{staticClass:"botname"}):(
                                item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                            )?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceId))]):_vm._e()]):(msg.type == 'answer_rich_text_pro')?_c('div',{staticClass:"media-body"},[_c('div',{domProps:{"innerHTML":_vm._s(msg.content.html)}}),_c('Recocell',{attrs:{"item":msg.content}}),_c('span',{staticClass:"time-span"},[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source == 'GUORAN_BOT')?_c('span',{staticClass:"botname"}):(
                                item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                            )?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceId))]):_vm._e()],1):(msg.type == 'answer_image')?_c('div',{staticClass:"media-body"},[_c('p',[_c('el-image',{staticStyle:{"width":"35%"},attrs:{"src":msg.content.url}})],1),_c('span',{staticClass:"time-span"},[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source == 'GUORAN_BOT')?_c('span',{staticClass:"botname"}):(
                                item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                            )?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceId))]):_vm._e()]):(msg.type == 'answer_radio')?_c('div',{staticClass:"media-body-ps"},[_c('p',{staticClass:"other"},[_vm._v(_vm._s(msg.content.description))]),_vm._l((msg.content.options),function(option,index){return _c('p',{key:index,staticClass:"ps"},[_vm._v(" "+_vm._s(option.name)+" ")])}),_c('span',{staticClass:"time-span",staticStyle:{"margin-left":"15px","margin-top":"5px"}},[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source == 'GUORAN_BOT')?_c('span',{staticClass:"botname",staticStyle:{"margin-top":"5px","margin-right":"15px"}}):(
                                item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                            )?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceId))]):_vm._e()],2):(msg.type == 'action_satisfaction')?_c('div',{staticClass:"media-body-ps"},[_c('p',{staticClass:"other"},[_vm._v(" "+_vm._s(msg.content.satisfactionDes)+" ")]),_vm._l((['满意', '不满意']),function(option,index){return _c('p',{key:index,staticClass:"ps"},[_vm._v(" "+_vm._s(option)+" ")])}),_c('span',{staticClass:"time-span",staticStyle:{"margin-left":"15px","margin-top":"5px"}},[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source == 'GUORAN_BOT')?_c('span',{staticClass:"botname",staticStyle:{"margin-top":"5px","margin-right":"15px"}}):(
                                item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                            )?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceId))]):_vm._e()],2):(msg.type == 'answer_video')?_c('div',{staticClass:"media-body"},[_c('p',[(msg.type === 'answer_video')?_c('video-player',{staticClass:"video-player vjs-custom-skin",staticStyle:{"width":"350px"},attrs:{"options":_vm.videoOptions(msg.content)}}):_vm._e()],1),_c('span',[_vm._v(_vm._s(new Date(item.time).Format( "yyyy-MM-dd hh:mm:ss" )))]),(item.source == 'GUORAN_BOT')?_c('span',{staticClass:"botname"}):(
                                item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'
                            )?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceId))]):_vm._e()]):(msg.type == 'answer_voice')?_c('div',{staticClass:"media-body"},[_c('p',[(msg.type === 'answer_voice')?_c('AnswerVoice',{ref:"audioTagCell",refInFor:true,attrs:{"msg":msg},on:{"stopOtherAudio":_vm.stopOtherAudio}}):_vm._e()],1),_c('Recocell',{attrs:{"item":msg.content}}),_c('span',[_vm._v(_vm._s(new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")))]),(item.source == 'GUORAN_BOT')?_c('span',{staticClass:"botname"}):(item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER')?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceId))]):_vm._e()],1):(msg.type == 'answer_doc_knowledge')?_c('div',{staticClass:"media-body"},[_c('p',[(msg.type === 'answer_doc_knowledge')?_c('AnswerDocknowledge',{attrs:{"msg":msg,"isMessageRecord":true}}):_vm._e()],1),_c('Recocell',{attrs:{"item":msg.content}}),_c('span',[_vm._v(_vm._s(new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")))]),(item.source == 'GUORAN_BOT')?_c('span',{staticClass:"botname"}):(item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER')?_c('span',{staticClass:"botname"},[_vm._v(_vm._s(item.sourceId))]):_vm._e()],1):(msg.type == 'action_transfer')?_c('div'):(msg.type == 'action_transfer_2_ewechat')?_c('div'):(
                            msg.type === 'action_send_message_to_bot'
                        )?_c('div',[_c('span',[_vm._v(_vm._s(msg.content.description))]),_vm._l((msg.content.options),function(cell,index){return _c('span',{key:'_' + index,staticClass:"link-cell cursor"},[_vm._v(_vm._s(cell.name))])})],2):(msg.type == 'action_fast_select')?_c('div',{staticClass:"media-body-ps"},[_c('p',{staticClass:"other"},[_vm._v("【"+_vm._s(_vm.$t('trainList.detailList.fastSelect'))+"】")]),_vm._l((msg.content.list),function(option,index){return _c('p',{key:index,staticClass:"ps"},[_vm._v(" "+_vm._s(option.text)+" ")])})],2):_c('div',[_c('p',[_vm._v(_vm._s(msg))])])]):_vm._e()])}):_vm._e()],2)})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-image"},[_c('img',{attrs:{"src":require("./../../../assets/images/chatboticon.png")}}),_c('span',[_vm._v("BOT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-image"},[_c('img',{attrs:{"src":require("./../../../assets/images/chatboticon.png")}}),_c('span',[_vm._v("BOT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-image"},[_c('img',{attrs:{"src":require("./../../../assets/images/chatboticon.png")}}),_c('span',[_vm._v("BOT")])])
}]

export { render, staticRenderFns }